import React, { useState } from 'react';
import { Form, Button, InputGroup, Container, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Yacht-filter.css'
import { Helmet } from 'react-helmet';
import '../assets/css/style.css';
import favicon from '../assets/images/favicon.webp';
import Header from '../Header';
import HeroForm from '../HeroForm';
import { Link } from 'react-router-dom';
import ScrollArrows from '../ScrollArrows';
import yacht_42ft from'../assets/images/dubai-yacht-images/42ft-dubai-yacht-dubai-marina-yacht-rental.webp';
import yacht_44ft from'../assets/images/dubai-yacht-images/44ft-dubai-yacht-dubai-marina-yacht-rental.webp';
import yacht_45ft from'../assets/images/dubai-yacht-images/45ft-dubai-yacht-dubai-marina-yacht-rental.webp';
import yacht_55ft from'../assets/images/dubai-yacht-images/55ft-azimut-yacht-dubai-dubai-marina-yacht-rental.webp';
import yacht_55_1ft from'../assets/images/dubai-yacht-images/55ft-yacht-dubai-dubai-marina-yacht-rental.webp';
import yacht_56ft from'../assets/images/dubai-yacht-images/56ft-yacht-dubai-dubai-marina-yacht-rental.webp';
import yacht_65ft from'../assets/images/dubai-yacht-images/65ft-yacht-dubai-dubai-marina-yacht-rental.webp';
import yacht_68ft from'../assets/images/dubai-yacht-images/68ft-yacht-dubai-dubai-marina-yacht-rental.webp';
import yacht_75ft from'../assets/images/dubai-yacht-images/75ft-yacht-dubai-dubai-marina-yacht-rental.webp';
import yacht_80ft from'../assets/images/dubai-yacht-images/80ft-yacht-dubai-dubai-marina-yacht-rental.webp';
import luxury_yacht_80ft from'../assets/images/dubai-yacht-images/80ft-luxury-yacht-dubai-dubai-marina-yacht-rental.webp';
import luxury_yacht_88ft from'../assets/images/dubai-yacht-images/88ft-luxury-yacht-dubai-dubai-marina-yacht-rental.webp';
import yacht_90ft from'../assets/images/dubai-yacht-images/90ft-yacht-dubai-dubai-marina-yacht-rental.webp';
import luxury_yacht_90ft from'../assets/images/dubai-yacht-images/90ft-luxury-yacht-dubai-dubai-marina-yacht-rental.webp';
import vip_luxury_yacht_90ft from'../assets/images/dubai-yacht-images/90ft-luxury-vip-yacht-dubai-dubai-marina-yacht-rental.webp';
import luxury_yacht_95ft from'../assets/images/dubai-yacht-images/95ft-luxury-yacht-dubai-dubai-marina-yacht-rental.webp';
import family_yacht_100ft from'../assets/images/dubai-yacht-images/100ft-family-yacht-dubai-dubai-marina-yacht-rental.webp';
import premium_luxury_yacht_101ft from'../assets/images/dubai-yacht-images/101ft-premium-luxury-yacht-dubai-dubai-marina-yacht-rental.webp';
import Footer from '../Footer';


const yachtsData = [
  {
    id: 1,
    name: "42Ft Yacht Dubai",
    imgSrc: yacht_42ft,
    maxGuests: 12,
    price: 449,
    location: "Dubai",
    path: "/yacht42ft",
  },
  {
    id: 2,
    name: "44Ft Yacht Dubai",
    imgSrc: yacht_44ft,
    maxGuests: 12,
    price: 499,
    location: "Dubai",
    path: "/yacht44ft",
  },
  {
    id: 3,
    name: "45Ft Yacht Dubai",
    imgSrc: yacht_45ft,
    maxGuests: 12,
    price: 499,
    location: "Dubai",
    path: "/yacht45ft",
  },
  {
    id: 4,
    name: "55Ft Azimut Yacht Dubai",
    imgSrc: yacht_55ft,
    maxGuests: 14,
    price: 549,
    location: "Dubai",
    path: "/yacht_azimut_55ft",
  },
  {
    id: 5,
    name: "55Ft Yacht Dubai",
    imgSrc: yacht_55_1ft,
    maxGuests: 22,
    price: 599,
    location: "Dubai",
    path: "/yacht_luxury_55ft",
  },
  {
    id: 6,
    name: "56Ft Yacht Dubai",
    imgSrc: yacht_56ft,
    maxGuests: 20,
    price: 699,
    location: "Dubai",
    path: "/yacht_luxury_56ft",
  },
  {
    id: 7,
    name: "65Ft Yacht Dubai",
    imgSrc: yacht_65ft,
    maxGuests: 25,
    price: 749,
    location: "Dubai",
    path: "/yacht_luxury_65ft",
  },
  {
    id: 8,
    name: "68Ft Yacht Dubai",
    imgSrc: yacht_68ft,
    maxGuests: 25,
    price: 799,
    location: "Dubai",
    path: "/yacht_luxury_68ft",
  },
  {
    id: 9,
    name: "75Ft Yacht Dubai",
    imgSrc: yacht_75ft,
    maxGuests: 30,
    price: 949,
    location: "Dubai",
    path: "/yacht_luxury_75ft",
  },
  {
    id: 10,
    name: "80Ft Yacht Dubai",
    imgSrc: yacht_80ft,
    maxGuests: 35,
    price: 1199,
    location: "Dubai",
    path: "/yacht_80ft",
  },
  {
    id: 11,
    name: "80Ft Luxury Yacht Dubai",
    imgSrc: luxury_yacht_80ft,
    maxGuests: 35,
    price: 1199,
    location: "Dubai",
    path: "/yacht_luxury_80ft",
  },
  {
    id: 12,
    name: "88Ft Luxury Yacht Dubai",
    imgSrc: luxury_yacht_88ft,
    maxGuests: 50,
    price: 1899,
    location: "Dubai",
    path: "/yacht_luxury_88ft",
  },
  {
    id: 13,
    name: "90Ft Yacht Dubai",
    imgSrc: yacht_90ft,
    maxGuests: 25,
    price: 799,
    location: "Dubai",
    path: "/yacht_90ft",
  },
  {
    id: 14,
    name: "90Ft Luxury Yacht Dubai",
    imgSrc: luxury_yacht_90ft,
    maxGuests: 40,
    price: 1499,
    location: "Dubai",
    path: "/yacht_luxury_90ft",
  },
  {
    id: 15,
    name: "90Ft Luxury VIP Yacht",
    imgSrc: vip_luxury_yacht_90ft,
    maxGuests: 50,
    price: 2399,
    location: "Dubai",
    path: "/yacht_luxury_vip_90ft",
  },
  {
    id: 16,
    name: "95Ft Luxury Yacht Dubai",
    imgSrc: luxury_yacht_95ft,
    maxGuests: 50,
    price: 1499,
    location: "Dubai",
    path: "/yacht_luxury_95ft",
  },
  {
    id: 17,
    name: "100Ft Family Yacht Dubai",
    imgSrc: family_yacht_100ft,
    maxGuests: 80,
    price: 2699,
    location: "Dubai",
    path: "/yacht_family_100ft",
  },
  {
    id: 18,
    name: "101Ft Premium Luxury Yacht Dubai",
    imgSrc: premium_luxury_yacht_101ft,
    maxGuests: 65,
    price: 2499,
    location: "Dubai",
    path: "/yacht_premium_luxury_101ft",
  },
  // Add more yacht data here...
];


const YachtRental = () => {
  const [formData, setFormData] = useState({
    date: new Date(),
    timeFrom: 12,
    timeTo: 16,
    guests: 6,
    promo: '',
  });

  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const [yachts, setYachts] = useState(yachtsData);
  const [promoApplied, setPromoApplied] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // State for error message

  const handleDateChange = (date) => {
    setFormData({ ...formData, date });
    setDatePickerOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const convertTo12HourFormat = (hour) => {
    const period = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
    return `${formattedHour}:00 ${period}`;
  };

  const handleTimeChange = (name, value) => {
    const newValues = { ...formData, [name]: value };

    if (name === 'timeFrom' && value >= newValues.timeTo) {
      newValues.timeFrom = newValues.timeTo - 1;
    }

    if (name === 'timeTo' && value <= newValues.timeFrom) {
      newValues.timeTo = newValues.timeFrom + 1;
    }

    setFormData(newValues);
  };

  const handlePromoApply = () => {
    setErrorMessage(''); // Reset error message

    if (formData.promo === 'YACHT20') {
      setPromoApplied(true);
      const discountedYachts = yachts.map(yacht => ({
        ...yacht,
        price: Math.round(yacht.price * 0.8), // Apply 20% discount
      }));
      setYachts(discountedYachts);
    } else {
      setPromoApplied(false);
      setErrorMessage('Invalid or expired promo code.'); // Set error message for invalid code
    }
  };

  // Filter yachts based on guests count
  const filteredYachts = yachts.filter(yacht => yacht.maxGuests >= formData.guests);

  return (
    <div>
      <Helmet>

<title>Yachts in Dubai - Best Price Deal</title>
<meta name="description" content="Find the best price deals on yachts in Dubai. Book now to enjoy luxury yachts, 24/7 service, and exclusive offers."/>
<link rel="canonical" title="" href="https://dubaiyachtevents.com/yacht_rental"/>
<meta name="keywords" content="Dubai yacht rental, luxury yacht Dubai, rent yacht Dubai, yacht party Dubai, book dubai yacht, book dubai boat, boat rental dubai, dubai boat ride, dubai marina boat, watersports dubai, dubai waterfun, dubai parasailing, water events dubai, dubai yacht events, yacht rental dubai, boat charter dubai, boat cruises dubai, luxury cruise dubai, yacht rental in dubai, boat trip dubai, sea trip dubai, boat hire dubai, boat hire in dubai, fishing trip dubai, boat rent dubai, boat tour in dubai, yacht charter dubai, dubai sea cruise, private charter dubai, dubai yacht charter, dubai yacht rental, fishing trips dubai, rent boat dubai, boat tour dubai, rent a boat dubai, rent a yacht dubai, sea tour dubai, dubai boat rental, dubai boat rentals, boat rental in dubai, boat hire in dubai, boat rental dubai, boat hire dubai, yacht rental in duabi, boat rentals dubai, yacht charters dubai, boat charter dubai, yacht charters in dubai, rent boat in dubai, rent a boat dubai, rent boat dubai, sea tour dubai, sea trip dubai, yacht charter dubai, yacht charter in dubai, dubai yacht cruise, dubai boat charter, boat hire dubai"/>
<link rel="icon" title="" href={favicon} type="image/x-icon"></link>
      <script type="application/ld+json">
  {JSON.stringify({
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Yachts For Rent",
        "item": "https://dubaiyachtevents.com/yacht_rental"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Boats For Rent",
        "item": "https://dubaiyachtevents.com/boat_rental_dubai"
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": "WaterSports",
        "item": "https://dubaiyachtevents.com/#location"
      },
      {
        "@type": "ListItem",
        "position": 4,
        "name": "Dubai Events",
        "item": "https://dubaiyachtevents.com/"
      }
    ]
  })}
  </script>



     
     </Helmet>
      <main>
        <article>
          <section className="section super-yacht" id="super-yacht" style={{ marginTop: '5%' }}>
            <div className="container">
              <div className="title-wrapper">
                <h1 className="h2 section-title"><strong>Yacht Rental Dubai</strong></h1>
              </div>
              <p>You all know that living in Dubai is costly, but a <strong>yacht ride </strong>in Dubai is not as expensive. All people who love yacht rides can enjoy this experience. Confirm your budget and choose a yacht within your price range. Options include <strong>private yachts</strong>, <strong>shared tours</strong>, or <strong>luxury yachts</strong>.A <strong>Dubai yacht ride</strong> offers the opportunity to explore the city's important landmarks. Popular sights include <strong>Dubai Marina</strong>, <strong>Palm Jumeirah</strong>, <strong>Burj Al Arab</strong>, and <strong>Atlantis,The Palm</strong>. Additionally, you can enjoy activities like sunbathing, swimming, underwater diving, <strong>fishing</strong>, and <strong>water sports</strong>, with dining and entertainment options on board.The best time to experience a yacht ride is from October to April. Sunset cruises are particularly beautiful. Book in advance for a memorable experience.Enjoy the <strong>dubai yacht party</strong> .</p>
              <br />
              <Header />
              <ScrollArrows />

              <Container className="charter-container">
                <h3 className="mb-4">Yacht Charter Booking</h3>
                <Form id="online_booking_form">
                  <Row className="d-flex justify-content-between align-items-center flex-wrap">
                    {/* Date Picker, Time From, Time To, Guests, and Promo Code inputs */}
                    <Col md={2} className="mb-3">
                      <Form.Group controlId="book_date">
                        <Form.Label>Select date</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <i className="glyphicon glyphicon-calendar text-primary"></i>
                          </InputGroup.Text>
                          <div className="position-relative">
                            <Button
                              className="form-control date-picker-btn"
                              onClick={() => setDatePickerOpen((prev) => !prev)}
                            >
                              {formData.date.toLocaleDateString('en-GB', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                              })}
                            </Button>
                            {isDatePickerOpen && (
                              <div className="date-picker-box">
                                <DatePicker
                                  selected={formData.date}
                                  onChange={handleDateChange}
                                  inline
                                  filterDate={(date) => date >= new Date()}
                                  onClickOutside={() => setDatePickerOpen(false)}
                                />
                              </div>
                            )}
                          </div>
                        </InputGroup>
                      </Form.Group>
                    </Col>

                    {/* Time From Input */}
                    <Col md={2} className="mb-3">
                      <Form.Group controlId="book_time_from">
                        <Form.Label>Charter time (from)</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type="range"
                            name="timeFrom"
                            min="1"
                            max="24"
                            value={formData.timeFrom}
                            onChange={(e) => handleTimeChange('timeFrom', parseInt(e.target.value))}
                            style={{ width: '100%' }}
                          />
                          <InputGroup.Text>{convertTo12HourFormat(formData.timeFrom)}</InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                    </Col>

                    {/* Time To Input */}
                    <Col md={2} className="mb-3" id='col'>
                      <Form.Group controlId="book_time_to">
                        <Form.Label>Charter time (to)</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type="range"
                            name="timeTo"
                            min="1"
                            max="24"
                            value={formData.timeTo}
                            onChange={(e) => handleTimeChange('timeTo', parseInt(e.target.value))}
                            style={{ width: '100%' }}
                          />
                          <InputGroup.Text>{convertTo12HourFormat(formData.timeTo)}</InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                    </Col>

                    {/* Guests Input */}
                    <Col md={2} className="mb-3" id='col'>
                      <Form.Group controlId="book_guests">
                        <Form.Label>Number of guests (up to)</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type="range"
                            name="guests"
                            min="1"
                            max="120"
                            value={formData.guests}
                            onChange={handleChange}
                            style={{ width: '100%' }}
                          />
                          <InputGroup.Text>{formData.guests}</InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                    </Col>

                    {/* Promo Code Input */}
                    <Col md={3} className="mb-3 d-flex align-items-center justify-content-center" id='gift'>
                      <Form.Group controlId="book_promo" className="w-100">
                        <Form.Label>Gift Code</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type="text"
                            name="promo"
                            placeholder="Gift Code"
                            className="text-uppercase placeholder-italic"
                            value={formData.promo}
                            onChange={handleChange}
                          />
                          <Button className="gift-card" variant="primary" onClick={handlePromoApply}>
                            Apply
                          </Button>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>

                {promoApplied && <p className="text-success">Gift applied: 20% discount!</p>}
                {errorMessage && <p className="text-danger">{errorMessage}</p>} 

      

    </Container>


 

  
  
  
            <ul className="super-yacht-list">
          {filteredYachts.map((yacht) => (
            <li key={yacht.id}>
              <div className="super-yacht-card">
                <figure className="card-banner">
                  <img src={yacht.imgSrc} title={yacht.name} alt={yacht.name} loading="lazy" width="440" height="300" className="w-100" />
                </figure>
  
                <div className="card-content">
                  <div className="card-title-wrapper">
                    <h2 className="h3 card-title">
                      <Link title={`Redirect to ${yacht.name} page`} to={yacht.path}><strong>{yacht.name}</strong></Link>
                    </h2>
                    <data className="place" value="Dubai">Dubai</data>
                  </div>
                  <ul className="card-list">
                    <li className="card-list-item">
                      <ion-icon name="people-outline"></ion-icon>
                      <span className="card-item-text">{yacht.maxGuests} People</span>
                    </li>
                    <li className="card-list-item">
                      <ion-icon name="calendar-outline"></ion-icon>
                      <span className="card-item-text">Date: {formData.date.toLocaleDateString()}</span>
                    </li>
                    <li className="card-list-item">
                      <ion-icon name="time-outline"></ion-icon>
                      <span className="card-item-text"> {convertTo12HourFormat(formData.timeFrom)} - {convertTo12HourFormat(formData.timeTo)}</span>
                    </li>
                    <li className="card-list-item">
                      <ion-icon name="pricetag-outline"></ion-icon>
                      <span className="card-item-text">
                        Discount :{promoApplied ? "30% " : "10%"}
                      </span>
                    </li>
                  </ul>
  
                  <div className="card-price-wrapper">
                    <p className="card-price">
                      <strong>AED {yacht.price}</strong> / hour
                    </p>
                    <button className="btn fav-btn" aria-label="Add to favourite list">
                      <ion-icon name="heart-outline"></ion-icon>
                    </button>
                    <Link className="btn" title={`Redirect to ${yacht.name} page`} to={yacht.path}>Book Now</Link>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
          </div>
        </section>
      
        
        </article>
        </main>
  
  
  <HeroForm/>

  <section class="section get-start">
        <div class="container">

          <h2 class="h2 section-title"><strong>Book a Yacht in Dubai for Party</strong> in just four simple steps</h2>

          <ul class="get-start-list">

            <li>
              <div class="get-start-card">

                <div class="card-icon icon-1">
                  <ion-icon name="search-outline"></ion-icon>
                </div>

                <h3 class="card-title">Search for a Yacht</h3>

                <p class="card-text">
                  Search for the <strong>yacht </strong>in Dubai from the group of listings
                </p>

                <Link title="" to="#super-yacht" class="card-link">Get started</Link>

              </div>
            </li>

            <li>
              <div class="get-start-card">

                <div class="card-icon icon-2">
                  <ion-icon name="boat-outline"></ion-icon>
                </div>

                <h3 class="card-title">Choose a Yacht</h3>

                <p class="card-text">
                  Choose the <strong>best luxury yacht </strong>in Dubai that particularly attracts you the most 
                </p>

              </div>
            </li>

            <li>
              <div class="get-start-card">

                <div class="card-icon icon-3">
                  <ion-icon name="checkmark-outline"></ion-icon>
                </div>

                <h3 class="card-title">Check the Description</h3>

                <p class="card-text">
                  Confirm the yacht by checking if the description includes the expected features
                </p>

              </div>
            </li>

            <li>
              <div class="get-start-card">

                <div class="card-icon icon-4">
                  <ion-icon name="call"></ion-icon>
                </div>

                <h3 class="card-title">Make a deal</h3>

                <p class="card-text">
                  Click 'Rent Now' option below the yacht to finalize your deal on WhatsApp
                </p>

              </div>
            </li>

          </ul>

        </div>
      </section>
  
  
  <Footer/>

</div>

  );
};

export default YachtRental;
