import './App.css';
import Home from './Home';
import { Route, Routes } from 'react-router-dom';
import Yacht42ft from './yachts/Yacht42ft';
import Yacht44ft from './yachts/Yacht44ft';
import Yacht45ft from './yachts/Yacht45ft';
import Yacht55ft from './yachts/Yacht_azimut_55ft';
import YachtLuxury55ft from './yachts/Yacht_luxury_55ft';
import YachtLuxury56ft from './yachts/Yacht_luxury_56ft';
import YachtLuxury65ft from './yachts/Yacht_luxury_65ft';
import YachtLuxury68ft from './yachts/Yacht_luxury_68ft';
import YachtLuxury75ft from './yachts/Yacht_luxury_75ft';
import Yacht80ft from './yachts/Yacht_80ft';
import YachtLuxury80ft from './yachts/Yacht_luxury_80ft';
import YachtLuxury88ft from './yachts/Yacht_luxury_88ft';
import Yacht90ft from './yachts/Yacht_90ft';
import YachtLuxury90ft from './yachts/Yacht_luxury_90ft';
import YachtLuxuryVip90ft from './yachts/Yacht_luxury_vip_90ft';
import YachtLuxury95ft from './yachts/Yacht_luxury_95ft';
import YachtFamily100ft from './yachts/Yacht_family_100ft';
import YachtPremiumLuxury101ft from './yachts/Yacht_premium_luxury_101ft';
import Boat33ft from './boats/Boat_33ft';
import Boat36ft from './boats/Boat_36ft';
import Boat40ft from './boats/Boat_40ft';
import SpeedBoat from './boats/Speed_boat';
import AboutUs from './dubai_uae/About-us';
import BestYachtRental from './dubai_uae/Best-yacht-rental-dubai';
import BoatRidePrice from './dubai_uae/Boat-ride-dubai-price';
import CheapestYachtRental from './dubai_uae/Cheapest-yacht-rental-dubai';
import ContactUs from './dubai_uae/Contact-us';
import DubaiYachtPrices from './dubai_uae/Dubai-yacht-rental-prices';
import DubaiYachtTourPrice from './dubai_uae/Dubai-yacht-tour-price';
import LuxuryYachtRental from './dubai_uae/Luxury-yacht-rental-dubai';
import PartyYachtRental from './dubai_uae/Party-yacht-rental-dubai';
import PrivateYachtRental from './dubai_uae/Private-yacht-rental-dubai';
import YachtRentalMarina from './dubai_uae/Yacht-rental-dubai-marina';
import BoatRentalDubai from './dubai_uae/Boat-rental-dubai';
import YachtRental from './dubai_uae/Yacht-rental';
import NotFound from './dubai_uae/Not-found';







function App() {
  return (
    <>
        <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/yacht42ft" element={<Yacht42ft />} />
                <Route path="/yacht44ft" element={<Yacht44ft />} />
                <Route path="/yacht45ft" element={<Yacht45ft />} />
                <Route path="/yacht_azimut_55ft" element={<Yacht55ft />} />
                <Route path="/yacht_luxury_55ft" element={<YachtLuxury55ft />} />
                <Route path="/yacht_luxury_56ft" element={<YachtLuxury56ft />} />
                <Route path="/yacht_luxury_65ft" element={<YachtLuxury65ft />} />
                <Route path="/yacht_luxury_68ft" element={<YachtLuxury68ft />} />
                <Route path="/yacht_luxury_75ft" element={<YachtLuxury75ft />} />
                <Route path="/yacht_80ft" element={<Yacht80ft />} />
                <Route path="/yacht_luxury_80ft" element={<YachtLuxury80ft />} />
                <Route path="/yacht_luxury_88ft" element={<YachtLuxury88ft />} />
                <Route path="/yacht_90ft" element={<Yacht90ft />} />
                <Route path="/yacht_luxury_90ft" element={<YachtLuxury90ft />} />
                <Route path="/yacht_luxury_vip_90ft" element={<YachtLuxuryVip90ft />} />
                <Route path="/yacht_luxury_95ft" element={<YachtLuxury95ft />} />
                <Route path="/yacht_family_100ft" element={<YachtFamily100ft />} />
                <Route path="/yacht_premium_luxury_101ft" element={<YachtPremiumLuxury101ft />} />
                <Route path="/boat_33ft" element={<Boat33ft />} />
                <Route path="/boat_36ft" element={<Boat36ft />} />
                <Route path="/boat_40ft" element={<Boat40ft />} />
                <Route path="/speed_boat" element={<SpeedBoat />} />
                <Route path="/about_us" element={<AboutUs />} />
                <Route path="/best_yacht_rental" element={<BestYachtRental />} />
                <Route path="/boat_ride_price" element={<BoatRidePrice />} />
                <Route path="/cheapest_yacht_rental" element={<CheapestYachtRental />} />
                <Route path="/contact_us" element={<ContactUs />} />
                <Route path="/dubai_yacht_prices" element={<DubaiYachtPrices />} />
                <Route path="/dubai_yacht_tour_price" element={<DubaiYachtTourPrice />} />
                <Route path="/luxury_yacht_rental" element={<LuxuryYachtRental />} />
                <Route path="/party_yacht_rental" element={<PartyYachtRental />} />
                <Route path="/private_yacht_rental" element={<PrivateYachtRental />} />
                <Route path="/yacht_rental_marina" element={<YachtRentalMarina />} />
                <Route path="/boat_rental_dubai" element={<BoatRentalDubai />} />
                <Route path="/yacht_rental" element={<YachtRental />} />
                <Route path="*" element={<NotFound />} />


       </Routes>
    </>
   
    
  );
}

export default App;
