import React from 'react';
import { Helmet } from 'react-helmet';
import '../assets/css/boat_styles.css';
import { Link } from 'react-router-dom';
import ScrollArrows from '../ScrollArrows';
import Header from '../Header';
import favicon from '../assets/images/favicon.webp';
import boat_40ft from'../boat_assets/images/40ft-boat-dubai-dubai-marina-boat-rental/40ft-boat-dubai-dubai-marina-boat-rental.webp';
import boat_40ft_bedroom from'../boat_assets/images/40ft-boat-dubai-dubai-marina-boat-rental/40-ft-aquaholic-boat-bedroom.webp';
import boat_40ft_sofa from'../boat_assets/images/40ft-boat-dubai-dubai-marina-boat-rental/40-ft-aquaholic-boat-sofa.webp';
import boat_40ft_private_room from'../boat_assets/images/40ft-boat-dubai-dubai-marina-boat-rental/40-ft-aquaholic-boat-private-room.webp';
import boat_40ft_side_view from'../boat_assets/images/40ft-boat-dubai-dubai-marina-boat-rental/40-ft-aquaholic-boat-private-room-side-view.webp';
import Footer from '../Footer';
import BoatImgShowcase from './BoatImageShowcase';



function Boat40ft() {
    return (
    
        <div className='body'>

     <Helmet>
     
     <title>40Ft Boat In Dubai Marina @349 AED</title>
     <meta name="description" content="Check out the 40 ft boat in Dubai Marina, now including a 10% Instant discount for the summer sale. Book now to get the discounted price!"/>
     <meta name="keywords" content="book dubai boat, 40ft boat dubai, mini boat dubai, small boat dubai, dubai marina boat, boat rental dubai, dubai boat ride" />
     <link rel="canonical" href="https://dubaiyachtevents.com/boat_40ft"/>
     <link rel="icon" title="" href={favicon} type="image/x-icon"></link>
      <script type="application/ld+json">
      {JSON.stringify({
        "@context": "https://schema.org",
        "@graph": [
          {
            "@type": "WebPage",
            "@id": "https://dubaiyachtevents.com/boat_40ft",
            "url": "https://dubaiyachtevents.com/boat_40ft",
            "name": "40Ft Reel Magic Boat In Dubai Marina @349 AED",
            "description": "Check out the 40 ft boat in Dubai Marina, now including a 10% Instant discount for the summer sale. Book now to get the discounted price!",
            "publisher": {
              "@type": "Organization",
              "name": "Dubai Yacht Events",
              "logo": {
                "@type": "ImageObject",
                "url": "https://dubaiyachtevents.com/static/media/logo.b4a5c51688c411ee299c.webp"
              },
              "url": "https://dubaiyachtevents.com/",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+971545619773",
                "contactType": "Customer Service",
                "areaServed": ["EN", "AE", "HI", "ML", "TA"]
              }
            }
          },
          {
            "@type": "WebSite",
            "name": "Dubai Yacht Events",
            "alternateName": "Dubai Yacht Ride",
            "url": "https://dubaiyachtevents.com/",
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://query.dubaiyachtevents.com/search?q={search_term_string}"
              },
              "query-input": "required name=search_term_string"
            }
          },
          {
            "@type": "Product",
            "name": "40Ft Boat Dubai-Dubai Marina Boat Rental",
            "description": "Check out the 40 ft boat in Dubai Marina, now including a 10% Instant discount for the summer sale. Book now to get the discounted price!",
            "sku": "YACHT002",
            "brand": {
              "@type": "Brand",
              "name": "Dubai Yacht Events"
            },
            "offers": {
              "@type": "Offer",
              "price": "349.00",
              "priceCurrency": "AED",
              "priceValidUntil": "2024-12-31",
              "availability": "https://schema.org/InStock",
              "url": "https://dubaiyachtevents.com/boat_40ft",
              "seller": {
                "@type": "Organization",
                "name": "Dubai Yacht Events"
              }
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.9",
              "reviewCount": "5589"
            },
            "image": [
              "https://dubaiyachtevents.com/static/media/40ft-boat-dubai-dubai-marina-boat-rental.1a6b7d1ceccb64df5a20.webp",
              "https://dubaiyachtevents.com/static/media/40-ft-aquaholic-boat-bedroom.8c6fe7f5723a0edef4e1.webp",
              "https://dubaiyachtevents.com/static/media/40-ft-aquaholic-boat-sofa.f0b546746632495c63ff.webp",
              "https://dubaiyachtevents.com/static/media/40-ft-aquaholic-boat-private-room.ffea03cd52da064bc47f.webp",
              "https://dubaiyachtevents.com/static/media/40-ft-aquaholic-boat-private-room-side-view.789eb6eb375513ed8601.webp"
            ]
          },
          {
            "@type": "FAQPage",
            "mainEntity": [
              {
                "@type": "Question",
                "name": "How to rent 40ft boat in dubai?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Contact our support(dubaiyachtevents.com) via call or watsapp then Confirm the price and book you slot"
                }
              },
              {
                "@type": "Question",
                "name": "Where i can ride this 40 ft boat in dubai?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "This 40ft boat is available in dubai marina.You can start your ride from dubai marina."
                }
              },
              {
                "@type": "Question",
                "name": "Is this 40 ft boat is an speed boat in dubai?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes 40ft boat in dubai is an speed boat."
                }
              },
              {
                "@type": "Question",
                "name": "How to book 40ft speed boat in dubai marina?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "dubaiyachtevents.com is the best website to book speed boat in Dubai with 0% commission."
                }
              },
              {
                "@type": "Question",
                "name": "What is the price for the 40ft speed boat in dubai?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "the price for the speed boat in dubai is approximately AED 349"
                }
              }
            ]
          },
          {
            "@type": "Place",
            "name": "Dubai Marina",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Dubai Marina",
              "addressLocality": "Dubai",
              "addressRegion": "Dubai",
              "addressCountry": "AE"
            }
          },
          {
            "@type": "LocalBusiness",
            "name": "Dubai Yacht Events",
            "image": "https://dubaiyachtevents.com/static/media/logo.b4a5c51688c411ee299c.webp",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Dubai Marina",
              "addressLocality": "Dubai",
              "addressRegion": "Dubai",
              "addressCountry": "AE"
            },
            "url": "https://dubaiyachtevents.com/",
            "telephone": "+971545619773",
            "priceRange": "349 AED",
            "sameAs": [
            "https://m.facebook.com/vcyachtsdubai/",
            "https://www.instagram.com/boatrental_dubai?igsh=MTUxYW1oeDJoMXR2Mg==",
            "https://pin.it/2CuYXWb",
            "https://x.com/boatrentdubai?t=SmkLRKWMHCWGQMhQJ55UtQ&s=09",
            "https://www.youtube.com/channel/UC9SsG2PjxUsKrMc9QdhuhRQ",
            "https://www.linkedin.com/in/boat-rental-dubai-6391b5256/",
            "https://boatrentaldubaimarina.blogspot.com/"
            ],
            "openingHoursSpecification": [
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                  "Sunday"
                ],
                "opens": "00:00",
                "closes": "23:59"
              }
            ]
          }
        ]
      })}
      </script>
  <script type="application/ld+json">
  {JSON.stringify({
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Yachts For Rent",
        "item": "https://dubaiyachtevents.com/yacht_rental"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Boats For Rent",
        "item": "https://dubaiyachtevents.com/boat_rental_dubai"
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": "WaterSports",
        "item": "https://dubaiyachtevents.com/#location"
      },
      {
        "@type": "ListItem",
        "position": 4,
        "name": "Dubai Events",
        "item": "https://dubaiyachtevents.com/"
      }
    ]
  })}
  </script>




     </Helmet>

       <Header/>
        
       <ScrollArrows />
        <div className = "boat-card-wrapper">
          <div className = "boat-card">
          <BoatImgShowcase
              boat_1={boat_40ft}
              boat_2={boat_40ft_bedroom}
              boat_3={boat_40ft_sofa}
              boat_4={boat_40ft_private_room}
              boat_5={boat_40ft_side_view}
              boat_1_alt="40ft boat in dubai"
              boat_2_alt="40ft boat bedroom in dubai"
              boat_3_alt="40ft boat side view in dubai"
              boat_4_alt="40ft boat private room in dubai"
              boat_5_alt="40ft boat sofa in dubai"
            />
            <div className = "boat-product-content">
              <h2 className = "boat-product-title">40Ft Boat Dubai</h2>
              <Link to = "#" className = "boat-product-link">Visit Dubai</Link>
              <div className = "boat-product-rating">
                <i className = "fas fa-star"></i>
                <i className = "fas fa-star"></i>
                <i className = "fas fa-star"></i>
                <i className = "fas fa-star"></i>
                <i className = "fas fa-star-half-alt"></i>
                <span>4.7(54234)</span>
              </div>
    
              <div className = "boat-product-price">
                <p className = "boat-last-price">Old Price: <span>AED 489.00</span></p>
                <p className = "boat-new-price">New Price: <span><b>AED 450.00 (5%)</b></span></p>
              </div>
    
              <div className = "boat-product-detail">
                <h2>About This Boat: </h2>
                <p>The 40Ft Boat Dubai-Dubai Marina Boat Rental is a high-end boat. It is a luxury boat with colorful design. Its speed is really Max level.</p>
                <ul>
                  <li>Name: <span><b>40Ft Boat Dubai-Dubai Marina Boat Rental</b></span></li>
                  <li>Hours: <span><b>Flexible Hours</b></span></li>
                  <li>Category: <span><b>Dubai Boat</b></span></li>
                  <li>Location: <span><b>Dubai Marina</b></span></li>
                  <li>PLaces: <span><b>Al over Dubai</b></span></li>
                  <li>Includes: <span><b>Juice,Fishing Equipments etc</b></span></li>
                  <li>Add ons: <span><b>Food,Watersports etc</b></span></li>
                </ul>
              </div>
    
              <div className = "boat-purchase-info">
                
                <Link to="https://wa.me/971545619773?text=Hi%20DubaiYachtEvents,%20I%20am%20interested%20in%20your%2040ft%20Aquaholic%20Boat.%20Can%20we%20finalize%20our%20deal%3F" className = "boat-btn">
                  Watsapp
                </Link>
                <Link to="https://wa.me/971545619773?text=Hi%20DubaiYachtEvents,%20I%20am%20interested%20in%20your%2040ft%20Aquaholic%20Boat.%20Can%20we%20finalize%20our%20deal%3F" className = "boat-btn">Rent Now</Link>
              </div>
    
              <div className = "boat-social-links">
                <p>Share At: </p>
                <Link to = "#">
                  <i className = "fab fa-facebook-f"></i>
                </Link>
                <Link to = "#">
                  <i className = "fab fa-twitter"></i>
                </Link>
                <Link to = "#">
                  <i className = "fab fa-instagram"></i>
                </Link>
                <Link to = "#">
                  <i className = "fab fa-whatsapp"></i>
                </Link>
                <Link to = "#">
                  <i className = "fab fa-pinterest"></i>
                </Link>
              </div>
              
            </div>

            <div className='boat-details'>

            <h2>Description</h2><br/>
            <p>The 40Ft Boat Dubai-Dubai Marina Boat Rental is a modern, stylish boat. It looks very stylish and fast <br/>
            Maximum <strong>10 peoples</strong> can ride in this boat. <br/>
            we provide high quality life jacket for all passengers. <br/>
            For all 10 people, we provide cool drinks and drinks based on your choice <br/>
            All the above features are included with the price.
          </p>

          </div>
          </div>
        </div>
    
  
    
      <Footer/>
    
    

      </div>
);

}

export default Boat40ft;